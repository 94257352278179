import gsap from "gsap";

let $cursor = document.querySelector(".cursor");
let $buttons = document.querySelectorAll(".interactable");
const $cursorTail = document.querySelector(".cursor-tail");

document.body.addEventListener("mousemove", onMouseMove);

$buttons.forEach((e) => {
  e.addEventListener("mouseenter", () => {
    $cursor.classList.add("focus-cursor");
    $cursorTail.classList.add("focus-cursor");
    let $r = document.querySelector(".roller");

    $r.classList.remove("focus-roller");
  });

  e.addEventListener("mouseleave", () => {
    $cursor.classList.remove("focus-cursor");
    $cursorTail.classList.remove("focus-cursor");
    let $r = document.querySelector(".roller");

    $r.classList.remove("focus-roller");
  });
});

function onMouseMove(e) {
  gsap.to($cursorTail, 0.4, {
    x: e.clientX,
    y: e.clientY,
  });
  gsap.to($cursor, 0.15, {
    x: e.clientX,
    y: e.clientY,
  });
}

//=======>> ROLLER <<=======//
document.addEventListener("DOMContentLoaded", () => {
  let $rollers = document.querySelectorAll(".roller");

  $rollers.forEach((roller) => {
    if (window.getComputedStyle(roller).display !== "none") {
      let $rollerTrigger = roller.querySelector(".roller__head-container");
      const img = roller.querySelector(".roller__thumbnail");

      if (img && img.src.endsWith("#")) {
        img.style.display = "none";
        console.log("no image found");
      }

      if ($rollerTrigger) {
        $rollerTrigger.addEventListener("click", () => {
          roller.classList.toggle("focus-roller");
        });
      }

      const ctrlLeft = roller.querySelector(".go-left");
      const ctrlRight = roller.querySelector(".go-right");
      const status = roller.querySelector(".status");

      let items = roller.querySelectorAll(".roll-item");
      let current = 0;
      let max = items.length;

      // Initialize carousel display
      function updateCarousel() {
        items.forEach((item, index) => {
          item.style.display = index === current ? "block" : "none";
          gsap.to(item, {
            x: index === current ? 0 : 100,
            duration: 0.5,
            ease: "power2.Out",
          });
        });

        if (status) {
          status.innerHTML = `${current + 1}/${max}`;
        }
      }

      // Display the first item on load
      updateCarousel();

      if (ctrlLeft) {
        ctrlLeft.addEventListener("click", (e) => {
          current = current === 0 ? max - 1 : current - 1;
          updateCarousel();
        });
      }

      if (ctrlRight) {
        ctrlRight.addEventListener("click", (e) => {
          current = current === max - 1 ? 0 : current + 1;
          updateCarousel();
        });
      }
    } else {
      console.log("Roller is hidden, skipping event listener attachment");
    }
  });
});

//=======>> PLACEHOLDER <<=======//

let $placeholderNews = document.querySelector(".placeholder-news");
$placeholderNews.innerHTML = "aiaiai's here!";

let $placeholderHello = document.querySelector(".placeholder-hello");
let $content = [
  "Pas de solutions toutes faites, pas de chemins trop faciles : nous prenons le temps de comprendre vos besoins, d’explorer des idées innovantes et de concevoir des projets sur mesure. Nous ne vous proposons que ce que nous ferions pour nous-mêmes – rien de moins que l’excellence. Basés sur le Röstigraben, nous sommes bien plus qu’une agence bilingue : nous sommes des créateurs de ponts, entre les langues, les cultures et vos ambitions. Et parce que nous repoussons sans cesse les limites, nous avons intégré une cellule d’intelligence artificielle directement au cœur de notre approche. Elle booste vos stratégies de communication, transforme vos données en opportunités et donne une nouvelle dimension à vos projets. \n\nDesign graphique audacieux, événements inoubliables, vidéos qui captivent – avec nous, vos idées ne se contentent pas de prendre vie, elles façonnent l’avenir.",
  "Keine vorgefertigten Lösungen, keine zu bequemen Wege: Wir nehmen uns die Zeit, Ihre Bedürfnisse zu verstehen, innovative Ideen zu erforschen und massgeschneiderte Projekte auszuarbeiten. Wir bieten Ihnen nur das, was wir auch für uns selbst tun würden - nämlich absolut hervorragende Qualität. Wir sind nicht nur eine Bilingue-Agentur am Röstigraben: Wir sind Brückenbauer - zwischen Sprachen, Kulturen und Ihren Ambitionen. Und weil wir die Grenzen immer wieder neu ausloten, haben wir eine Zelle für künstliche Intelligenz direkt in den Kern unseres Ansatzes integriert. \n\nGewagtes Grafikdesign, unvergessliche Veranstaltungen, fesselnde Videos - bei uns werden Ihre Ideen nicht nur zum Leben erweckt, sondern gestalten die Zukunft.",
];

let index = 0;

document.addEventListener("DOMContentLoaded", () => {
  $placeholderHello.innerHTML = $content[1];
});

setInterval(() => {
  gsap.to($placeholderHello, {
    opacity: 0,
    scale: 0.95,
    duration: 0.2,
    onComplete: () => {
      $placeholderHello.innerHTML = $content[index];
      index = (index + 1) % $content.length;
      gsap.to($placeholderHello, {
        opacity: 1,
        scale: 1,
        duration: 0.2,
      });
    },
  });
}, 25000);

let $hello = document.querySelector(".hello");

let $timerContainer = document.createElement("div");
$timerContainer.classList.add("timer-container");
$hello.appendChild($timerContainer);

let $timer = document.createElement("div");
$timer.classList.add("timer");
$timerContainer.appendChild($timer);

function startTimer() {
  gsap.to($timer, {
    width: "100%",
    duration: 25,
    ease: "linear",
    onComplete: () => {
      $timer.style.width = "0%";
      startTimer();
    },
  });
}

startTimer();

//=======>> COOKIE BANNER <<=======//

let $cookieBanner = document.querySelector(".cookie-banner__container");
let $cookieBannerButton = $cookieBanner.querySelector(".cookie-banner__button");

$cookieBannerButton.addEventListener("click", () => {
  $cookieBanner.style.display = "none";
});

document.addEventListener("DOMContentLoaded", () => {
  gsap.to($cookieBanner, {
    opacity: 1,
    y: 0,
    duration: 0.5,
    delay: 2,
  });
});

//=======>> DETECT DEVTOOLS <<=======//

const detectDevTools = () => {
  const isDevToolsOpened = /./;
  isDevToolsOpened.toString = () => {
    console.log(
      "💀\n≤))≥\n_||_ Wanna see the backstages ?\n\n Try by understand my code haha"
    );
    return "Looking something in my code...";
  };

  console.log(isDevToolsOpened);
};

detectDevTools();
